var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"achors"},[_c('div',{staticClass:"container container--abs"},[_c('div',{staticClass:"achors__item achors__top",class:{
				is_hidden: _vm.position === 0 || _vm.openContacts,
				white: _vm.hoveredWorks,
				on_index: _vm.onMainPage,
			},on:{"click":_vm.backToPrevPage}},[_c('p',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("back"))+"\n\t\t\t")]),_vm._v(" "),_c('img',{staticClass:"svg",attrs:{"src":require("../assets/images/back.svg"),"alt":"go back"}})]),_vm._v(" "),_c('div',{staticClass:"achors__item achors__bottom",class:{
				is_hidden: _vm.position === 3 || _vm.openContacts || !_vm.onMainPage,
				white: _vm.hoveredWorks,
			},on:{"click":_vm.slideBottom}},[_c('p',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("down"))+"\n\t\t\t")]),_vm._v(" "),_c('img',{staticClass:"svg",attrs:{"src":require("../assets/images/down.svg"),"alt":"go bottom"}})])]),_vm._v(" "),_c('div',{staticClass:"achors__item achors__contacts",class:{white: _vm.hoveredWorks}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.openContacts),expression:"!openContacts"}],on:{"click":_vm.contactsClickEvent}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("contactsTitle"))+"\n\t\t")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.openContacts),expression:"openContacts"}],on:{"click":_vm.contactsClickEvent}},[_vm._v(_vm._s(_vm.$t("close")))])]),_vm._v(" "),_c('div',{staticClass:"achors__item achors__fb",class:{white: _vm.hoveredWorks}},[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/lestroweb/"}},[_c('p',[_vm._v(_vm._s(_vm.$t("subscribe")))]),_vm._v(" "),_c('img',{attrs:{"src":require("../assets/images/facebook.svg"),"alt":"facebook"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }